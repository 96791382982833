const PUBLIC_HOST = 'https://dev-emea.api.loreal.net/global/hr/v1/paplatform/backend';
export const TYPESENSE_HOST = 'https://dev-emea.api.loreal.net/global/hr/v1/paplatform/typesense';
export const POWER_BI_API = "https://api.powerbi.com/v1.0/myorg";
export const GRAPH_ENDPOINT_GET_PHOTO ='https://graph.microsoft.com/v1.0/me/photo/$value';
export const GRAPH_USERS = 'https://graph.microsoft.com/v1.0/users';
export const GRAPH_GROUP_AD = 'https://graph.microsoft.com/v1.0/groups';

export const environment = {
  production: false,
  clientId: '40524428-8304-4c96-a70b-8fb52a18a4a8',
  authority: 'e4e1abd9-eac7-4a71-ab52-da5c998aa7ba',
  redirectUrl: 'https://dev-peopleanalytics.loreal.com',
  publicHost: PUBLIC_HOST,
  uploadMaxRetries: 5,
  uploadChunkSize: 8 * 1024 * 1024, // 8 Mio => recommended by Google
  carolStatisticsURL: 'https://dev-statscarol.loreal.wans/BOE/BI/logon.jsp'
};

// API endpoints
export const API_URLS = {
  USER_DETAILS: `${PUBLIC_HOST}/users/me`,
  REPORTS: `${PUBLIC_HOST}/reports`,
  SAVE_REPORTS: `${PUBLIC_HOST}/reports/bulk`,
  SECTIONS: `${PUBLIC_HOST}/sections`,
  GROUPS: `${PUBLIC_HOST}/groups`,
  PLATFORM_FOLDERS: `${PUBLIC_HOST}/platforms`,
  DATASOURCES: `${PUBLIC_HOST}/datasources`,
  TAGS: `${PUBLIC_HOST}/tags`,
  FILES: `${PUBLIC_HOST}/files`,
  NEWS: `${PUBLIC_HOST}/news`,
  ELEARNINGS: `${PUBLIC_HOST}/elearnings`,
  USERS: `${PUBLIC_HOST}/users`,
  ANALYTICS: `${PUBLIC_HOST}/analytics`,
  LANGUAGES: `${PUBLIC_HOST}/languages`,
  WORKSPACES: `${PUBLIC_HOST}/powerbi-workspaces`
}